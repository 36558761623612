<!-- eslint-disable vue/no-v-html -->
<template>
  <div v-if="renderedContent.length > 0">
    <template v-for="(node, index) in renderedContent">
      <component
        :is="node.blok.component"
        v-if="isBlokNode(node)"
        :key="`c_${index}`"
        :blok="node.blok"
      />
      <NuxtImg
        v-else-if="isImageNode(node)"
        :key="`i_${index}`"
        format="webp"
        placeholder
        :src="node.image.src"
        :alt="node.image.alt"
        :title="node.image.title"
        :data-copyright="node.image.copyright"
        loading="lazy"
      />
      <div v-else :key="`t_${index}`" ref="textNodes" class="text" v-html="node.html" />
    </template>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import type { BlokBody, DocContent, ImageContent, Content, CleanedContent } from '@/types/richtext'

const props = defineProps({
  blok: {
    type: Object as PropType<DocContent>,
    required: false,
    default: () => {}
  }
})

interface BlokNode {
  blok: BlokBody
}

interface ImageNode {
  image: ImageContent['attrs']
}

interface HtmlNode {
  html: string
}

type Node = BlokNode | ImageNode | HtmlNode

const isImageNode = (node: Node): node is ImageNode => 'image' in node
const isBlokNode = (node: Node): node is BlokNode => 'blok' in node

const textNodes = ref<HTMLDivElement[]>([])

const renderedContent = computed(() => {
  if (typeof props.blok !== 'object' || !('content' in props.blok)) {
    console.error('Not valid rich text content provided to RichtextResolver')
    return []
  }

  return props.blok.content.reduce<Node[]>((acc, content) => {
    return acc.concat(convertRichtextContent(content))
  }, [])
})

const removeEmptyParagraphs = (content: Content): CleanedContent | null => {
  if (content.type === 'paragraph') {
    if (content.content === undefined) {
      return null
    } else {
      for (let i = 0; i < content.content.length; i++) {
        const subProcessed = removeEmptyParagraphs(content.content[i])
        if (subProcessed === null) {
          content.content.splice(i, 1)
          i--
        } else {
          content.content[i] = subProcessed
        }
      }
      return content as CleanedContent
    }
  } else {
    return content
  }
}

const convertRichtextContent = (content: Content): Node[] => {
  const cleanedContent = removeEmptyParagraphs(content)
  if (cleanedContent === null) {
    return []
  }

  if (cleanedContent.type === 'blok') {
    return cleanedContent.attrs.body.map((blok) => ({ blok }))
  }

  if (cleanedContent.type === 'paragraph') {
    if (cleanedContent.content[0].type === 'image') {
      return [{ image: cleanedContent.content[0].attrs }]
    }
  }

  const html = useStoryblokApi()
    .richTextResolver.render({
      type: 'doc',
      content: [cleanedContent]
    })
    .replace('//a.storyblok.com', '//assets.pflege.de')
    .replace('href="//', 'href="/')
  return [{ html }]
}

const useRichtextResolverInternalLinkTransformer = (textNodes: HTMLDivElement[]) => {
  const router = useRouter()
  const onAnchorClick = (e: MouseEvent) => {
    e.stopPropagation()
    const target = e.currentTarget! as HTMLAnchorElement
    if (target.hasAttribute('uuid') && target.hasAttribute('href')) {
      e.preventDefault()
      router.push({ path: target.getAttribute('href')! })
    }
  }
  const visitAnchorNodes = (handler: (node: HTMLAnchorElement) => void) => {
    textNodes.forEach((textNode) => {
      const listOfAnchorNodes = textNode.getElementsByTagName('a')
      for (const anchorNode of listOfAnchorNodes) {
        handler(anchorNode)
      }
    })
  }
  onMounted(() => {
    visitAnchorNodes((anchorNode) => anchorNode.addEventListener('click', onAnchorClick))
  })
  onBeforeUnmount(() => {
    visitAnchorNodes((anchorNode) => anchorNode.removeEventListener('click', onAnchorClick))
  })
}
useRichtextResolverInternalLinkTransformer(textNodes.value)
</script>

<style scoped>
.text {
  margin-bottom: 1rem;
}
.text:last-child {
  margin-bottom: 0;
}
.text:has(+ .infotooltip),
.text:has(+ .infotooltip) > :deep(*),
.infotooltip + .text,
.infotooltip + .text > :deep(*) {
  display: inline;
}

:deep(img) {
  max-width: 100%;
}

:deep(.text) ul p {
  display: inline;
}
</style>
